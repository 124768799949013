/* eslint-disable object-curly-newline */
import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { closePopOut } from '../../actions/index';
import ProductCarouselAsset from '../Product/productCarousel';

const DumbPopout = (props) => {
  const { isOpen = false, isMobileDevice, closePopOutModal, cmsContentItem } = props;
  const cmsContentId = cmsContentItem?.sys?.id;

  useEffect(() => {
    if (isMobileDevice && isOpen) {
      import('./popout.scss');
    }
  }, [isMobileDevice, isOpen]);

  return (
    <Modal
      ariaHideApp={false}
      closeTimeoutMS={500}
      className="verticalProductList verticalProductList_from-right"
      overlayClassName="verticalProductList__overlay"
      isOpen={isMobileDevice && isOpen}
      onRequestClose={closePopOutModal}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      shouldReturnFocusAfterClose
    >
      <button className="verticalProductList__close" onClick={closePopOutModal} />
      <ProductCarouselAsset cmsContentItem={cmsContentItem} key={cmsContentId} />
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    isOpen: get(state, 'cms.popout.visible', false),
    isMobileDevice: get(state, 'device.isMobilePhone', false),
    cmsContentItem: get(state, 'cms.popout.productCarousel', {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  closePopOutModal: () => {
    dispatch(closePopOut());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DumbPopout);
